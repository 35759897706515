<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <!-- Search -->
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md3>
                  <v-daterange
                    v-model="search.dateTimeRange"
                    :input-props="inputProps"
                    :locale="$i18n.locale"
                    :start-label="$t('evoucher.issuance.startDate')"
                    :end-label="$t('evoucher.issuance.endDate')"
                    :menu-props="menuProps"
                    :separator-label="$t('evoucher.to')"
                    style="width: 100%;"
                    display-format="dd/MM/yyyy"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    :clearable="true"
                    v-model="search.voucherName"
                    :label="$t('voucherUsed.voucherName')"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    :clearable="true"
                    v-model="search.staffName"
                    :label="$t('users.staff')"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    :clearable="true"
                    v-model="search.workpieceCode"
                    :label="$t('report.quantityVoucherSupplierToAgent.workpieceCode')"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-select
                    :clearable="true"
                    v-model="search.agentId"
                    :items="agentList"
                    :label="$t('entity.agent')"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSearch"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <!-- Receive list -->
        <material-card
          :title="$t('report.statisticsNumberOfVouchersIssuedToAgentLevel2')"
          color="green"
          flat
          full-width
        >
          <template>
            <!-- Create check voucher -->
            <v-btn
              v-if="!isEntityDisabled()"
              color="success"
              dark
              style="float: right;"
              @click="onExportExcel()"
            >
              <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
              <span style="text-transform: none;">{{
                $t("voucherReport.exportExcel")
              }}</span>
            </v-btn>
          </template>
          <!-- <v-btn
            color="success"
            dark
            style="float: right;"
            @click="onExportToExcelFile"
          >
            <v-icon>mdi-file-export</v-icon>
            <span style="text-transform: none;">{{ $t('voucherReport.exportExcel') }}</span>
          </v-btn> -->
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="voucherReportHeaders"
            :items="voucherReportList"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }">
              <td
                v-for="(header, index) in voucherReportHeaders"
                :key="header.value + index"
              >
                <span>{{ item[header.value] }}</span>
              </td>
            </template>
          </v-data-table>
        </material-card>
        <pagination
          :total="paginate.totalPage"
          :current-page="paginate.currentPage"
          :row-per-page="paginate.rowPerPage"
          @onPageChange="onPageChange"
        />
      </v-flex>
    </v-layout>
    <loading-bar :is-loading="isLoading" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VoucherTransactionValue from 'enum/voucherTransactionValue'
import functionUtils from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
import EntityType from 'enum/entityType'
import XLSX from 'xlsx'
export default {
  components: {},
  data: () => ({
    voucherReportHeaders: [
      { text: 'common.nonumber', value: 'stt' },
      { text: 'voucherHistory.dateAction', value: 'dateAction' },
      { text: 'report.quantityVoucherSupplierToAgent.workpieceCode', value: 'workpieceCode' },
      { text: 'evoucher.issuance.workpiece', value: 'issuanceCode' },
      { text: 'voucherUsed.voucherName', value: 'voucherName' },
      { text: 'report.quantityVoucherSupplierToAgent.issueNumber', value: 'quantity' },
      // { text: 'report.releaseAgent', value: 'fromEntity' },
      { text: 'report.receivedAgent', value: 'toEntity' },
      { text: 'voucherHistory.peopleAction', value: 'userCreated' },
      { text: 'report.staffEmail', value: 'staffEmail' },
      { text: 'voucherHistory.receivingCustomer', value: 'receivingCustomer' },
      { text: 'report.quantityVoucherSoldInAgent.customerHaveUsed', value: 'customerHaveUsed' }
      // { text: 'evoucher.discount', value: 'discount' }
    ],
    voucherReportList: [],
    paginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    isLoading: false,
    //
    inputProps: { solo: false, style: { width: '270px' } },
    menuProps: { offsetY: true, closeOnContentClick: false },
    search: {
      voucherName: null,
      workpieceCode: null,
      agentId: null,
      staffName: null,
      dateTimeRange: {
        start: null,
        end: null
      }
    },
    agentList: [],
    exportExcelData: {
      headerList: [
        'common.nonumber',
        'voucherHistory.dateAction',
        'report.quantityVoucherSupplierToAgent.workpieceCode',
        'evoucher.issuance.workpiece',
        'voucherUsed.voucherName',
        'report.quantityVoucherSupplierToAgent.issueNumber',
        'report.receivedAgent',
        'voucherHistory.peopleAction',
        'report.staffEmail',
        'voucherHistory.receivingCustomer',
        'report.quantityVoucherSoldInAgent.customerHaveUsed'
      ],
      dataList: []
    }
  }),
  computed: {
    ...mapGetters([
      'QUANTITY_VOUCHER_SUPPLIER_TO_AGENT_DATA'
    ])
  },
  watch: {
    QUANTITY_VOUCHER_SUPPLIER_TO_AGENT_DATA () {
      let res = this.QUANTITY_VOUCHER_SUPPLIER_TO_AGENT_DATA
      // Handle paginate
      this.paginate.currentPage = res.paginate.currentPage
      this.paginate.totalPage = res.paginate.totalPage
      this.paginate.rowPerPage = res.paginate.perPage / 2
      // Handle response data
      let data = res.results
      this.voucherReportList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let obj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          dateAction: this.formatDate(data[i].date_created),
          workpieceCode: data[i].workpiece ? data[i].workpiece.code : '',
          issuanceCode: functionUtils.concatSuffixPrefix(data[i].issuance),
          quantity: data[i].quantity,
          fromEntity: data[i].from_entity.name,
          toEntity: data[i].to_entity.name,
          userCreated: functionUtils.concatFullname(data[i].user_created.last_name, data[i].user_created.first_name),
          discount: (data[i].discount.type === VoucherTransactionValue.PERCENT || !data[i].discount.type) ? (data[i].discount.value + '%') : (functionUtils.convertFormatNumberToMoney(data[i].discount.value) + ' VND'),
          voucherName: data[i].workpiece ? data[i].workpiece.name : '',
          staffEmail: data[i].user_created.email,
          receivingCustomer: data[i].quantity_distributed_to_customer,
          customerHaveUsed: data[i].total_voucher_used
        }
        this.voucherReportList.push(obj)
      }
    }
  },
  created () {
    this.getReportStatisticalQuantityVoucherSupplierToAgent()
    this.getChildrenEntity()
  },
  methods: {
    isEntityDisabled: function () {
      return functionUtils.isCurrentEntityLocked() || functionUtils.isCurrentEntityExpired()
    },
    /**
     * Format date
     */
    formatDate: function (date) {
      return dateUtils.formatBeautyDate(date)
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.paginate.currentPage = page
      this.getReportStatisticalQuantityVoucherSupplierToAgent()
    },
    /**
     * Get report statistical quantity voucher supplier to agent
     */
    getReportStatisticalQuantityVoucherSupplierToAgent: function () {
      let filter = {
        params: {
          page: this.paginate.currentPage,
          startDate: this.search.dateTimeRange.start,
          endDate: this.search.dateTimeRange.end,
          voucherName: this.search.voucherName,
          workpieceCode: this.search.workpieceCode,
          agentId: this.search.agentId,
          staffName: this.search.staffName
        }
      }
      this.isLoading = true
      this.GET_QUANTITY_VOUCHER_SUPPLIER_TO_AGENT(filter).then(
        function () {
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    onSearch: function () {
      this.getReportStatisticalQuantityVoucherSupplierToAgent()
    },
    onExportExcel: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      let filter = {
        params: {
          startDate: this.search.dateTimeRange.start,
          endDate: this.search.dateTimeRange.end,
          voucherName: this.search.voucherName,
          workpieceCode: this.search.workpieceCode,
          agentId: this.search.agentId,
          staffName: this.search.staffName
        }
      }
      this.isLoading = true
      this.GET_QUANTITY_VOUCHER_SUPPLIER_TO_AGENT(filter)
        .then(
          function (res) {
            this.isLoading = false
            let data = res.data
            let dataList = this.getDataExcelJson(
              this.exportExcelData.headerList,
              data
            )
            this.createExcelFile(dataList)
          }.bind(this)
        )
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    getDataExcelJson: function (headerList, dataList) {
      let resultList = []

      //
      // let headerListLength = headerList.length
      let nonumberHeader = headerList[0]
      let dateActionHeader = headerList[1]
      let workpieceCodeHeader = headerList[2]
      let voucherIssuanceHeader = headerList[3]
      let voucherNameHeader = headerList[4]
      let issueNumberHeader = headerList[5]
      let receivedAgentHeader = headerList[6]
      let peopleActionHeader = headerList[7]
      let staffEmailHeader = headerList[8]
      let receivingCustomerHeader = headerList[9]
      let customerHaveUsedHeader = headerList[10]
      dataList.forEach((data, index) => {
        let item = {
          [this.$t(nonumberHeader)]: index + 1,
          [this.$t(dateActionHeader)]: this.formatDate(data.date_created),
          [this.$t(workpieceCodeHeader)]: data.workpiece ? data.workpiece.code : '',
          [this.$t(voucherIssuanceHeader)]: functionUtils.concatSuffixPrefix(data.issuance),
          [this.$t(voucherNameHeader)]: data.workpiece ? data.workpiece.name : '',
          [this.$t(issueNumberHeader)]: data.quantity,
          [this.$t(receivedAgentHeader)]: data.to_entity.name,
          [this.$t(peopleActionHeader)]: functionUtils.concatFullname(data.user_created.last_name, data.user_created.first_name),
          [this.$t(staffEmailHeader)]: data.user_created.email,
          [this.$t(receivingCustomerHeader)]: data.quantity_distributed_to_customer,
          [this.$t(customerHaveUsedHeader)]: data.total_voucher_used
        }
        resultList.push(item)
      })
      return resultList
    },
    createExcelFile: function (jsonData) {
      let dataList = []

      dataList = jsonData
      // export json to Worksheet of Excel
      // only array possible
      var assetFile = XLSX.utils.json_to_sheet(dataList)
      // var pokemonWS = XLSX.utils.json_to_sheet(this.Datas.pokemons)

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, assetFile, 'ReportVoucherUsedSheet') // sheetAName is name of Worksheet
      // XLSX.utils.book_append_sheet(wb, pokemonWS, 'pokemons')

      // export Excel file
      XLSX.writeFile(wb, 'ReportVoucherUsed.xlsx') // name of the file is 'book.xlsx'
    },
    getChildrenEntity: function () {
      let filter = {
        params: {
          excludeEntityType: EntityType.SITE
        }
      }
      this.isLoading = true
      this.GET_CHILDREN_ENTITY(filter)
        .then(
          function (res) {
            let dataList = res.data.data
            this.agentList = []
            dataList.forEach(e => {
              let i = {
                value: e.id,
                text: e.name
              }
              this.agentList.push(i)
            })
            this.isLoading = false
          }.bind(this)
        )
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    ...mapActions([
      'GET_QUANTITY_VOUCHER_SUPPLIER_TO_AGENT',
      'GET_CHILDREN_ENTITY'
    ])
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: 960px) {
  .flex.lg5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}
/deep/.v-menu--inline {
  display: block;
}
</style>
